import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import { encode }from 'url-safe-base64'
import ConsentPopup from "../ConsentPopup";

export function NordicSection({patientConsent, setPatientConsent}) {
  const { t, i18n } = useTranslation();
  const currentUserId = useSelector(state => state.auth.currentUserId);
  
  const encoded_string = (patientId) =>{
    let current_time_stamp = new Date().toLocaleString()
    let unrapped_string = "patient_id=" + patientId + "&vendor=dna_life&time="+ current_time_stamp
    
    return encode(btoa(unrapped_string))
  }
  
  const handleAcceptConsent = (e) => {
  setPatientConsent(false)
  window.open("https://puregenomics.nordicvms.com/default.aspx?pgref=" + encoded_string(currentUserId), "_blank")
  };

  return (
    <div>    
      { (patientConsent) && <ConsentPopup
                    showModal={true}
                    content={t('patient_snp_nordic_consent_t1')}
                    showClose={true}
                    showButton={true}
                    acceptTerms={() => handleAcceptConsent()}
                    handleHide={()=> setPatientConsent(false)}
                    title={"PUREGENOMICS Genetic Data TERMS OF USE"}
                    />
      } 
    </div>
   
  );
}